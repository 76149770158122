import { useContext, useEffect, useState } from 'react';
import IdentityContext from 'Common/context/IdentityContext';
import { AccountType } from 'Common/functions/AccountType';

const getIsCustomer = (accountType: AccountType) =>
  accountType === AccountType.Customer ||
  accountType === AccountType._LegacyCustomer;

const useIsCustomer = (): boolean => {
  const {
    accountType,
  } = useContext(IdentityContext);
  const [isCustomer, setIsCustomer] = useState(
    accountType ? getIsCustomer(accountType) : false,
  );

  useEffect(() => {
    if (accountType) {
      setIsCustomer(getIsCustomer(accountType));
    }
  }, [accountType]);

  return isCustomer;
};

export default useIsCustomer;
