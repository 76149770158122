export type IDashboardKeys = {
  HOME: string;
  APPOINTMENT_DETAILS: string;
  SETTINGS: string;
  MANAGE_EMPLOYEES: string;
  LOCATIONS: string;
  CALENDAR: string;
  REFERRAL: string;
  SERVICE_OFFERINGS: string;
  ACTION_REQUIRED: string;
  DISCOUNTS: string;
  APPOINTMENTS: string;
  SUPPORT: string;
};

export const DashboardKeys: IDashboardKeys = {
  HOME: '0',
  APPOINTMENT_DETAILS: '1',
  SETTINGS: '2',
  MANAGE_EMPLOYEES: '4',
  LOCATIONS: '5',
  CALENDAR: '6',
  REFERRAL: '8',
  SERVICE_OFFERINGS: '9',
  ACTION_REQUIRED: '10',
  DISCOUNTS: '11',
  APPOINTMENTS: '12',
  SUPPORT: '13',
};

