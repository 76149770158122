'use client';

import { Box, Flex, Text, Link as TULink } from 'theme-ui';
import FirebaseAuth from '../FirebaseAuth';
import signinWithIdToken from 'Common/functions/signinWithIdToken';
import { AppCookie, setCookie } from 'Common/functions/Cookie';
import { useCallback, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { Divider, Modal } from 'antd';
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  getAuth,
  signInWithCredential,
} from 'firebase/auth';
import fbCompat from 'firebase/compat/app';
import 'firebase/compat/auth';
import TOSCheckbox from 'Common/components/TOSCheckbox';
import migrateAnonymousIdentity from
  'Common/functions/migrateAnonymousIdentity';
import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import {
  getAnonymousUserIdToken,
  getLoginAndMergeUrl,
  getLoginOrLoginAndMergeUrl,
} from 'Common/functions/UpgradeAnonymousAccount';

type UpgradeAnonymousAccountProps = {
  onSigninSuccess: () => void;
  isOpen: boolean;
  title: string;
  onClose?: () => void;
  postLoginRedirectFlowReturnTo?: string;
}

const UpgradeAnonymousAccount: React.FC<UpgradeAnonymousAccountProps> = ({
  onSigninSuccess,
  isOpen,
  title,
  onClose,
  postLoginRedirectFlowReturnTo,
}) => {
  const [_key, _setKey] = useState(0);
  const [tosChecked, setTosChecked] = useState(false);
  const [tosError, setTosError] = useState(false);
  const client = useApolloClient();
  const router = useRouter();

  const getLoginUrl = useCallback(async () => {
    const anonUser = fbCompat.auth().currentUser;
    const anonUserIdToken = anonUser?.isAnonymous ?
      await getAnonymousUserIdToken(anonUser) :
      '';
    const loginUrl = getLoginOrLoginAndMergeUrl(
      anonUserIdToken,
      router,
      getLoginAndMergeUrl,
      postLoginRedirectFlowReturnTo,
    );

    return loginUrl;
  }, [(() => {
    try {
      return fbCompat.auth().currentUser;
    } catch(err) {
      console.error(err);
    }
  })(), router.asPath]);

  const signInCallback = (idToken: string) =>
    signinWithIdToken(idToken, true, () => {
      setCookie(AppCookie.PeazyIsLoggedIn, 'true');
      onSigninSuccess();
    }, () => {
      _setKey(_key + 1);
      setCookie(AppCookie.PeazyIsLoggedIn, '', -1);
      getAuth().signOut();
    });

  const navigateToLogin = () => {
    getLoginUrl().then(url => {
      if (url) {
        router.push(url);
      }
    });
  };

  return (
    <Modal
      open={isOpen}
      title={(
        <>
          {title}
          <Divider />
        </>
      )}
      footer={<></>}
      onCancel={() => onClose && onClose()}
      closable={Boolean(onClose)}
    >
      <Flex sx={{
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
      }}>
        <TOSCheckbox
          checked={tosChecked}
          onChange={() => setTosChecked(!tosChecked)}
          tosLink='https://bookpeazy.com/peazy-terms-of-use'
        />
        {tosError && !tosChecked && (
          <Text variant="error">
            You must accept the terms to continue.
          </Text>
        )}
        <Box
          data-testid="upgradeAnonymousAccount_fbAuthWrap"
          onClick={() => setTosError(!tosChecked)}
          sx={{ width: '100%', }}
        >
          <Box sx={tosChecked ? {} : { pointerEvents: 'none' }}>
            <FirebaseAuth
              className="firebase-auth"
              copyPrefix="Sign Up"
              uiConfig={{
                autoUpgradeAnonymousUsers: true,
                signInFlow: 'popup',
                signInOptions: [
                  EmailAuthProvider.PROVIDER_ID,
                  {
                    provider: GoogleAuthProvider.PROVIDER_ID,
                    customParameters: {
                      prompt: 'select_account'
                    }
                  }
                ],
                siteName: 'Peazy',
                callbacks: {
                  signInSuccessWithAuthResult: (data): boolean => {
                    (async (): Promise<void> => {
                      const { user } = data;
                      const idToken = await user.getIdToken();
                      signInCallback(idToken);
                    })();
                    // Return false here to prevent navigation
                    return false;
                  },
                  signInFailure: async (error) => {
                    const anonUserIdToken =
                      await fbCompat.auth().currentUser?.getIdToken();
                    const isUpgradeMergeError =
                      error.code ===
                      'firebaseui/anonymous-upgrade-merge-conflict';
                    if (isUpgradeMergeError) {
                      signInWithCredential(
                        getAuth(),
                        error.credential,
                      ).then(async d => {
                        const idToken = await d.user.getIdToken();
                        await migrateAnonymousIdentity(
                          client,
                          anonUserIdToken as string,
                          idToken
                        ).then(() => {
                          signInCallback(idToken);
                        });
                      });
                    } else {
                      Bugsnag.notify('Firebase signin error:' + error.toJSON());
                      _setKey(_key + 1);
                      getAuth().signOut();
                    }
                  }
                }
              }}
              firebaseAuth={fbCompat.auth()}
              _key={_key}
            />
          </Box>
        </Box>
        <Text
          variant="body3"
          sx={{
            fontSize: ['0.875rem', '1rem'],
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Already have an account?
          <Text onClick={() => navigateToLogin()}>
            <TULink variant="base" sx={{ ml: '0.3rem' }}>
              Log In
            </TULink>
          </Text>.
        </Text>
      </Flex>
    </Modal>
  );
};

export default UpgradeAnonymousAccount;

