import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { AppRoutePath } from 'Pages/routes';
import { SearchParam } from 'Common/functions/Search';
import NavItem from '../NavItem';
import { useRouter } from 'next/router';
import useIsCustomer from 'Common/hooks/useIsCustomer';

type NavTabProps = {
  target: string;
  children: React.ReactNode;
  style?: ThemeUIStyleObject;
  onClick?: () => void;
  value: string | null;
  linkVariant?: string;
  testId?: string;
};

const NavTab: React.FC<NavTabProps> = ({
  target,
  children,
  onClick,
  value,
  linkVariant,
  testId,
}) => {
  const router = useRouter();
  const isCustomer = useIsCustomer();

  const handleClick = (): void => {
    onClick && onClick();
    const path = isCustomer ?
      AppRoutePath.HOME :
      AppRoutePath.DASHBOARD;
    router.push({
      pathname: path,
      query: { [SearchParam.VIEW]: target },
    });
  };

  const isActiveRoute = value === target ? true : false;

  return (
    <NavItem
      testId={testId}
      onClick={handleClick}
      key={target}
      target={target}
      variant={linkVariant}
      isActiveRoute={isActiveRoute}
      value={value}
    >
      {children}
    </NavItem>
  );
};

export default NavTab;
