import React, { useMemo } from 'react';
import { Box, Link, ThemeUIStyleObject } from 'theme-ui';
import { AppRoutePath } from 'Pages/routes';

type NavItemProps = {
  target: AppRoutePath | string;
  children: React.ReactNode;
  style?: ThemeUIStyleObject;
  key?: string;
  onClick?: () => void;
  value?: string | null;
  isActiveRoute?: boolean;
  variant?: string;
  testId?: string;
};

const NavItem: React.FC<NavItemProps> = ({
  target,
  children,
  onClick,
  isActiveRoute,
  variant,
  testId,
}) => {
  const linkVariant = useMemo(() => {

    if (isActiveRoute) {
      switch (variant) {
      case 'nav.primary':
        return 'nav.primaryActive';
      case 'nav.secondary':
        return 'nav.secondaryActive';
      case 'default':
        return 'activeNav';
      }
    }
    return variant ?? 'defaultNav';

  }, [variant, isActiveRoute]);

  return (
    <Box
      data-testid={testId}
      onClick={onClick}
      key={target}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Link
        as="span"
        variant={linkVariant}
      >
        {children}
      </Link>
    </Box>
  );
};

export default NavItem;
