import React from 'react';
import styles from './style';
import { Box, Button, Flex, Text, Link as TULink } from 'theme-ui';
import { LockOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { AppRoutePath } from 'Pages/routes';
import Theme from 'Common/themePeazy.json';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { getAuth } from 'firebase/auth';
import { AppCookie, setCookie } from 'Common/functions/Cookie';

type HeaderActionsProps = {
  loggedIn: boolean;
  hideDashboardButton: boolean;
  hideSignupButton?: boolean;
  showAlreadyHaveAnAccountSign?: boolean
  signupButtonLink?: AppRoutePath;
  testIdPrefix?: string;
  onClickLogin?: () => void;
  onClickSignup?: () => void;
}

const HeaderActions: React.FC<HeaderActionsProps> = ({
  loggedIn,
  hideDashboardButton,
  hideSignupButton,
  showAlreadyHaveAnAccountSign,
  signupButtonLink,
  testIdPrefix = '',
  onClickLogin,
  onClickSignup,
}) => {
  const router = useRouter();
  const client = useApolloClient();

  const doLogout = () => {
    client.resetStore();
    client.cache.reset();
    setCookie(AppCookie.PeazyIsLoggedIn, '', -1);
    fetch('/api/do-logout').then(() => getAuth().signOut().then(
      () => router.push(AppRoutePath.LOGIN))
    );
  };

  const loginLink = (
    <TULink
      data-testid={`${testIdPrefix}headerActions_loginLink`}
      variant="buttons.plainText"
      as="span"
      sx={{
        mr: hideSignupButton ? 0 : '1rem',
        color: Theme.colors.black,
        fontSize: '1rem',
      }}
    >
      Log In
    </TULink>
  );

  const signupLink = (
    <Button
      data-testid={`${testIdPrefix}headerActions_signupLink`}
      data-cy="signup"
      variant={'cardButton'}
      sx={{
        whiteSpace: 'nowrap',
      }}
    >
      Sign Up
    </Button>
  );

  return (
    <Flex sx={styles.headerContent}>
      {loggedIn === true && (hideDashboardButton || (
        <Link href={AppRoutePath.BROWSE_SERVICE_TYPES}>
          <Button
            variant={'cardButton'}
            sx={{
              ...styles.accountActionButton,
              mr: '1rem',
            }}
          >
            Book a Service
          </Button>
        </Link>
      ))}
      {loggedIn ? (
        <TULink
          variant="buttons.plainHeadingUppercase"
          onClick={(): void => doLogout()}

          sx={{
            mr: '1rem',
          }}>
          <LockOutlined />
          Logout
        </TULink>
      ) : (
        <>
          {/^\/login/.test(router.pathname) || (
            <Flex
              sx={{
                gap: '1rem',
                alignItems: 'center'
              }}
            >
              {showAlreadyHaveAnAccountSign && (
                <Text
                  sx={{
                    color: Theme.colors.orange,
                    fontSize: '0.875rem',
                    textTransform: 'uppercase'
                  }}
                >
                  Already have an account?
                </Text>
              )}
              {onClickLogin ? (
                <Box onClick={() => onClickLogin()}>
                  {loginLink}
                </Box>
              ) : (
                <Link
                  href={AppRoutePath.LOGIN}
                  sx={{
                    textDecoration: 'none',
                  }}
                >
                  {loginLink}
                </Link>
              )}
            </Flex>
          )}
          {hideSignupButton ||
            (/^\/signup/.test(router.pathname) ||
              (onClickSignup ? (
                <Box
                  onClick={() => onClickSignup()}
                >
                  {signupLink}
                </Box>
              ) : (
                <Link
                  href={signupButtonLink || AppRoutePath.CUSTOMER_SIGNUP}
                >
                  {signupLink}
                </Link>
              ))
            )
          }
        </>
      )}
    </Flex>
  );
};

export default HeaderActions;
