import React from 'react';
import { Link as TULink } from 'theme-ui';
import { AppRoutePath } from 'Pages/routes';
import { getCurrentRoute } from 'Common/functions/Route';
import NavItem from '../NavItem';
import { useRouter } from 'next/router';
import Link from 'next/link';

type NavLinkProps = {
  target: AppRoutePath | string;
  children: React.ReactNode;
  onClick?: () => void;
  linkVariant?: string;
  testId?: string;
};

const NavLink: React.FC<NavLinkProps> = ({
  target,
  children,
  onClick,
  linkVariant,
  testId,
}) => {
  const router = useRouter();
  const currentRoute = getCurrentRoute(router.pathname);
  const isActiveRoute = currentRoute ? target === currentRoute.route : false;

  return (
    <Link
      href={target}
      sx={{ textDecoration: 'none' }}
    >
      <TULink
        data-testid={testId}
        href={target}
        sx={{ textDecoration: 'none' }}
        as="span"
      >
        <NavItem
          onClick={onClick}
          key={target}
          target={target}
          variant={linkVariant}
          isActiveRoute={isActiveRoute}
        >
          {children}
        </NavItem>
      </TULink>
    </Link>
  );
};

export default NavLink;

