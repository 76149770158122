import { ThemeUIStyleObject } from 'theme-ui';

const header: ThemeUIStyleObject = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  background: 'white',
  padding: 0,
  '& .ant-page-header-content': {
    paddingTop: 0,
  },
  '& *:not([href*=signup]) > button': {
    fontWeight: 900,
    color: 'black'
  }
};

const styles: Record<string, ThemeUIStyleObject> = {
  logoWrap: {
    display: 'flex',
    alignItems: 'center',
    '& em': {
      marginLeft: '4%',
      color: 'initial',
    },
  },
  headerLogo: {
    maxWidth: '100%'
  },
  header,
  headerPale: {
    ...header,
    boxShadow: '0px 1px 12px 6px #d6d6d6',
    marginBottom: '3%'
  },
  accountActionButton: {
    pointerEvents: 'initial',
    display: 'flex',
    alignItems: 'center',
  },
  headerContent: {
    alignItems: 'center',
    justifyContent:'center',
    '& *': {
      fontSize: '18px',
    }
  },
  navItems: {
    display: 'flex'
  },
};

export default styles;
