import { Flex, Label, Link as TULink } from 'theme-ui';
import { Checkbox } from 'antd';

type TOSCheckboxProps = {
  checked: boolean;
  onChange: () => void;
  tosLink: string;
}

const TOSCheckbox: React.FC<TOSCheckboxProps> = ({
  checked,
  onChange,
  tosLink,
}) => {

  return (
    <Flex
      sx={{
        gap: '1rem',
        '& .ant-checkbox-inner': {
          borderColor: 'black',
        }
      }}
    >
      <Label
        variant="text.body3"
        sx={{
          fontSize: ['0.875rem', '1rem']
        }}
      >
        <Checkbox
          data-testid="signupForm_tosCheckbox"
          data-cy="signupForm_tosCheckbox"
          checked={checked}
          onChange={() => onChange()}
          style={{
            marginRight: '0.5rem',
          }}
        />
        I agree to the{' '}
        <a
          href={tosLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TULink variant="base">
            Peazy Terms of Service
          </TULink>
        </a>
        {' '}and{' '}
        <a
          href="https://bookpeazy.com/peazy-privacypolicy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TULink variant="base">
            Privacy Policy
          </TULink>
        </a>
        .
      </Label>
    </Flex>
  );
};

export default TOSCheckbox;
