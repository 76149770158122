import { AppRoutePath } from 'Pages/routes';
import { SearchParam } from './Search';

export const getLoginAndMergeUrl = (
  idToken: string,
  returnTo: string,
) => {
  const search = new URLSearchParams({
    [SearchParam.POST_SIGNUP_MIGRATE_ANONYMOUS_ACCOUNT_ID_TOKEN]: idToken,
    [SearchParam.RETURN_TO]: returnTo,
  });
  const loginUrlQuery = search.toString();
  const loginUrl = `${AppRoutePath.LOGIN}?${loginUrlQuery}`;

  return loginUrl;
};

type router = {
  asPath: string;
}

type firebaseUser = {
  getIdToken: () => Promise<string>;
}

export const getLoginOrLoginAndMergeUrl = (
  anonUserIdToken: string | undefined,
  router: router,
  getLoginAndMergeUrl: (idToken: string, returnTo: string) => string,
  returnToRaw?: string,
) => {
  if (anonUserIdToken) {
    const returnTo = encodeURIComponent(returnToRaw || router.asPath);
    const loginUrl = getLoginAndMergeUrl(anonUserIdToken, returnTo);
    return loginUrl;
  }
  return AppRoutePath.LOGIN;
};

export const getAnonymousUserIdToken = async (
  anonymousUser: firebaseUser | null,
) => {
  if (anonymousUser) {
    const anonUserIdToken = await anonymousUser.getIdToken();
    return anonUserIdToken;
  }
};
