import {
  ApolloClient,
} from '@apollo/client';
import {
  V2Gateway_MigrateAnonymousIdentityDocument,
  V2Gateway_MigrateAnonymousIdentityMutation,
  V2Gateway_MigrateAnonymousIdentityMutationVariables,
} from 'Generated/graphql';

const migrateAnonymousIdentity = async (
  client: ApolloClient<object>,
  anonymousIdToken: string,
  ownerIdToken: string,
) => {
  const res =
    await client.mutate<
      V2Gateway_MigrateAnonymousIdentityMutation | null,
      V2Gateway_MigrateAnonymousIdentityMutationVariables
    >({
      mutation: V2Gateway_MigrateAnonymousIdentityDocument,
      variables: {
        anonymousIdToken,
        ownerIdToken,
      },
    });

  return res.data?.migrateAnonymousIdentity;
};

export default migrateAnonymousIdentity;

