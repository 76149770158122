import { ThemeUIStyleObject } from 'theme-ui';
import Theme from 'Common/themePeazy.json';

const styles: Record<string, ThemeUIStyleObject> = {
  accountInfoWrapper: {
    alignItems: 'center',
    columnGap: '16px',
    ':hover': { cursor: 'pointer' },
    position: 'relative',
    'div:nth-child(even)': {
      background: '#F8F8F8',
    },
  },
  accountInfoWrapperV2: {
    alignItems: 'center',
    columnGap: '16px',
    ':hover': { cursor: 'pointer' },
    position: 'relative',
    ':before': {
      bottom: '-100%',
      position: 'absolute',
      display: 'block',
      content: '""',
      width: '100%',
      height: '2rem'
    },
  },
  accountInfoContainer: {
    right: '-20px',
    position: 'absolute',
    borderRadius: '8px',
    opacity: 1,
    ':hover': { opacity: 1, transition: 'opacity 1s ease-in-out' },
  },
  accountInfoContainerV2: {
    right: '0',
    top: 'calc(100% + 1rem)',
    position: 'absolute',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.12)',
    opacity: 1,
    ':hover': { opacity: 1, transition: 'opacity 1s ease-in-out' },
  },
  accountInfoInnerContainer: {
    textAlign: 'left',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.12)',
    minWidth: '245px',
    position: 'relative',
    '> :first-of-type': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    '> :last-of-type': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
  accountInfoInnerContainerV2: {
    textAlign: 'left',
    py: '.5rem',
    backgroundColor: Theme.colors.newspaper,
    borderRadius: '8px',
    boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.12)',
    minWidth: '113px',
    position: 'relative',
    '> :first-of-type': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    '> :last-of-type': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
  accountItems: {
    justifyContent: 'start',
    alignItems: 'center',
    width: ['auto', 'auto', '100%'],
    fontSize: '1.125rem',
    color: 'primary',
    ' a': {
      color: 'primary',
    },
    '> div': {
      p: '1rem',
    },
  },
  accountItemsV2: {
    alignItems: 'end',
    ' >div': {
      py: '.5rem',
    }
  },
};

export default styles;
