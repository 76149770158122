import React, { useContext } from 'react';
import { Link } from 'theme-ui';
import { useApolloClient } from '@apollo/client';
import { AppRoutePath } from 'Pages/routes';
import { getAuth } from 'firebase/auth';
import { AppCookie, setCookie } from 'Common/functions/Cookie';
import useLocalStorage from 'Common/hooks/useLocalStorage';
import { AppLocalStorageKeys } from 'Common/functions/LocalStorage';
import IdentityContext from 'Common/context/IdentityContext';

type LogOutProps = {
  color?: string | string[];
  isOnMainNav?: boolean;
}

const LogOut: React.FC<LogOutProps> = ({
  isOnMainNav = false
}) => {
  const client = useApolloClient();
  const [unusedLocationUid, setLocationUid, removeLocationUid] =
    useLocalStorage(
      AppLocalStorageKeys.APPOINTMENT_REQUEST_WIDGET_LOCATION_UID_MAP,
      '',
    );
  const {
    getIdentityQuery,
    getUserQuery,
  } = useContext(IdentityContext);

  const onClickSignout = () => {
    removeLocationUid();
    fetch('/api/do-logout').then(
      () => getAuth().signOut().finally(
        () => client.cache.reset().finally(() => {
          client.resetStore().finally(() => {
            Promise.all([
              getIdentityQuery.refetch(),
              getUserQuery.refetch(),
            ]).finally(() => {
              setCookie(AppCookie.PeazyIsLoggedIn, '', -1);
              // Full navigation so all react state gets reset
              window.location.href = AppRoutePath.BROWSE_SERVICE_TYPES;
            });
          });
        })
      )
    );
  };

  return (
    <Link
      onClick={onClickSignout}
      variant={isOnMainNav ? 'nav.primary' : 'nav.secondary'}
    >
      Log Out
    </Link>
  );
};

export default LogOut;

