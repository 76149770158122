import React from 'react';
import Logo from 'Images/logo.turbopack-temp-svg';
import { Box } from 'theme-ui';

const HeaderLogo: React.FC<{ onClickLogo?: () => void; }> = (
  ({ onClickLogo }) => (
    <Box
      data-testid="headerLogo"
      sx={{
        mt: ['0.5rem', '1rem'],
        width: ['6rem', '8rem', '10rem'],
        height: ['1.5rem', '2.25rem', '3rem']
      }}
      onClick={() => {
        onClickLogo && onClickLogo();
      }}
    >
      <Logo />
    </Box>
  )
);

export default HeaderLogo;
