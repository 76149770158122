import React, { useMemo, useState, useCallback } from 'react';
import { AppRoutePath } from 'Pages/routes';
import NavLink from '../NavLink';
import NavTab from '../NavTab';
import { Text, Flex, Box, Link as TUILink } from 'theme-ui';
import ChevronDownSmall from 'Images/svg/chevron-down-small.turbopack-temp-svg';
import LogOut from './Logout';
import styles from '../styles';
import { DashboardKeys } from '@/types';
import { getAnalytics, logEvent } from 'firebase/analytics';
import MimoblTheme from 'Common/theme.json';
import { NavigationContentType } from '..';
import HeaderActions from 'Common/components/SiteHeader/HeaderActions';

const style = {
  ':hover': {
    fontWeight: ['bold', 'bold', 'unset'],
    textDecoration: ['underline', 'underline', 'unset'],
    color: ['primary', 'primary', 'secondary'],
  },
  fontSize: ['1.5rem', '1.5rem', '1.125rem'],
  fontFamily: 'Poppins'
};

type NavContentProps = {
  contentType: NavigationContentType;
  onSelect: () => void;
  value: string | null;
  onClickLogin?: () => void;
  onClickSignup?: () => void;
};

const NavContent: React.FC<NavContentProps> = ({
  contentType,
  onSelect,
  value,
  onClickLogin,
  onClickSignup,
}) => {
  const [accountInfo, showAccountInfo] = useState(false);

  const home = (
    <NavTab
      testId="navContent_Dashboard"
      key={DashboardKeys.HOME}
      target={DashboardKeys.HOME}
      linkVariant="nav.primary"
      onClick={() => {
        logEvent(
          getAnalytics(),
          'click_nav_button_dashboard',
        );
        onSelect && onSelect();
      }}
      value={value}
    >
      Dashboard
    </NavTab>
  );

  const requestAppointment = (
    <NavLink
      testId="navContent_bookAService"
      target={AppRoutePath.BROWSE_SERVICE_TYPES}
      key={AppRoutePath.BROWSE_SERVICE_TYPES}
      linkVariant="nav.primary"
      onClick={() => {
        logEvent(
          getAnalytics(),
          'click_nav_button_appointments',
        );
        onSelect && onSelect();
      }}
    >
      Book A Service
    </NavLink>
  );

  const Settings = React.memo(
    ({ text, isOnMainNav }: { text: string; isOnMainNav?: boolean }) => {
      return (
        <NavTab
          target={DashboardKeys.SETTINGS}
          key={DashboardKeys.SETTINGS}
          onClick={() => {
            logEvent(getAnalytics(), 'click_nav_button_settings');
            onSelect && onSelect();
          }}
          linkVariant={isOnMainNav ? 'nav.primary' : 'nav.secondary'}
          value={value}
        >
          {text}
        </NavTab>
      );
    }
  );

  const locations = useCallback((label: string, isOnMainNav?: boolean) => {
    return (
      <NavTab
        key={DashboardKeys.LOCATIONS}
        target={DashboardKeys.LOCATIONS}
        linkVariant={isOnMainNav ? 'nav.primary' : 'nav.secondary'}
        onClick={() => {
          logEvent(getAnalytics(), 'click_nav_button_locations');
          onSelect && onSelect();
        }}
        value={value}
      >
        {label}
      </NavTab>
    );
  }, [onSelect, value]);

  const support = useMemo(() => {
    return (
      <TUILink
        variant="nav.secondary"
        onClick={() => {
          logEvent(getAnalytics(), 'click_nav_button_support');
          onSelect && onSelect();
        }}
        href="mailto:support@bookpeazy.com"
      >
        Support
      </TUILink>
    );
  }, [onSelect]);

  const dropDownLogout = (
    <Box key='logout'>
      <LogOut />
    </Box>
  );

  const desktopAccountInfo = [
    <Box
      key="settings"
      sx={{
        color: 'primary',
        width: '100%',
        textAlign: 'left'
      }}
    >
      <Settings text="Payment" />
    </Box>,
    locations('Locations'),
    <Box key='support' sx={{
      color: 'primary',
      width: '100%',
      textAlign: 'left'
    }}>
      {support}
    </Box>,
    dropDownLogout
  ];

  const accountDropDown = (
    <Box sx={styles.accountInfoContainerV2}>
      <Box sx={styles.accountInfoInnerContainerV2}>
        {desktopAccountInfo.map((item, index) => {
          return (
            <Box sx={styles.accountItemsV2} key={index}>
              {item}
            </Box>
          );
        }
        )}
      </Box>
    </Box>
  );

  const desktopMyAccount = (
    <Box
      data-testid="navContent_myAccount"
      sx={{
        ...styles.accountInfoWrapperV2,
        display: ['none', 'none', 'unset']
      }}
      onMouseEnter={() => showAccountInfo(true)}
      onMouseLeave={() => showAccountInfo(false)}
      key="myaccount"
    >
      <Text sx={{
        color: MimoblTheme.colors.Black,
        display: 'flex',
        gap: '.5em',
        alignItems: 'center',
        fontSize: '1rem',
        fontFamily: 'Proxima Nova, sans-serif',
        textTransform: 'uppercase',
      }}>My Account&nbsp;
        <ChevronDownSmall />
      </Text>
      {accountInfo && accountDropDown}
    </Box>
  );

  const myAccount = [
    desktopMyAccount,
    <Box key='locations' sx={{
      display: ['unset', 'unset', 'none'],
      color: ['primary', 'primary', 'white'],
    }}>
      {locations('Location')}
    </Box>,
    <Box
      key="settings"
      sx={{
        display: ['unset', 'unset', 'none'],
        color: ['primary', 'primary', 'white'],
      }}
    >
      <Settings text="Payment" />
    </Box>,
    <Box key='support' sx={{
      display: ['unset', 'unset', 'none'],
      color: ['primary', 'primary', 'white'],
    }}>
      {support}
    </Box>,
    <Flex
      key="logout"
      sx={{
        display: ['unset', 'unset', 'none'],
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LogOut
        color={['primary', 'primary', 'white']}
      />
    </Flex>,
  ];

  const loginSignupAnon = (
    <HeaderActions
      loggedIn={false}
      hideDashboardButton
      onClickLogin={onClickLogin}
      onClickSignup={onClickSignup}
    />
  );

  const navContent: Record<NavigationContentType, React.ReactElement[]> = {
    [NavigationContentType.CUSTOMER]: [home, requestAppointment, ...myAccount],
    [NavigationContentType.SERVICE_PROVIDER]: [
      home,
      <NavTab
        testId="navContent_spManageServices"
        target={DashboardKeys.SERVICE_OFFERINGS}
        key={DashboardKeys.SERVICE_OFFERINGS}
        style={{
          ...style,
        }}
        onClick={() => {
          logEvent(
            getAnalytics(),
            'click_nav_button_service_offerings',
          );
          onSelect && onSelect();
        }}
        linkVariant='nav.primary'
        value={value}
      >
        Services
      </NavTab>,
      <NavTab
        target={DashboardKeys.REFERRAL}
        key={DashboardKeys.REFERRAL}
        style={{
          ...style,
        }}
        onClick={() => {
          logEvent(
            getAnalytics(),
            'click_nav_button_referral',
          );
          onSelect && onSelect();
        }}
        linkVariant='nav.primary'
        value={value}
      >
        Referrals
      </NavTab>,
      <Box
        data-testid="navContent_settings"
        key="settings"
        sx={{
          color: ['primary', 'primary', 'white'],
        }}
      >
        <Settings text="Settings" isOnMainNav />
      </Box>,
      <Flex
        data-testid="navContent_logout"
        key="logout"
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <LogOut color={['primary', 'primary', 'white']} isOnMainNav />
      </Flex>,
    ],
    [NavigationContentType.LOCATION_OWNER]: [
      home,
      <NavLink
        testId="navContent_scheduleAppointment"
        key={AppRoutePath.BUSINESS_BROWSE_SERVICE_TYPES}
        target={AppRoutePath.BUSINESS_BROWSE_SERVICE_TYPES}
        onClick={() => {
          logEvent(
            getAnalytics(),
            'click_nav_button_browse_services',
          );
          onSelect && onSelect();
        }}
        linkVariant='nav.primary'
      >
        Schedule Appointment
      </NavLink>,
      <Box
        data-testid="navContent_locations"
        key="locations"
        sx={{
          color: ['primary', 'primary', 'white'],
        }}
      >
        {locations('Office Locations', true)}
      </Box>,
      <Box
        key="settings"
        data-testid="navContent_settings"
        sx={{
          color: ['primary', 'primary', 'white'],
        }}
      >
        <Settings text="Payment" isOnMainNav />
      </Box>,
      <Flex
        data-testid="navContent_logout"
        key="logout"
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <LogOut color={['primary', 'primary', 'white']} isOnMainNav />
      </Flex>,
    ],
    [NavigationContentType.ANONYMOUS_CUSTOMER]: [requestAppointment, loginSignupAnon],
  };

  return <>{navContent[contentType]}</>;
};

export default NavContent;

