import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import MenuClose from 'Images/svg/menu-close.turbopack-temp-svg';
import MenuOpen from 'Images/svg/menu-open.turbopack-temp-svg';
import { Box, Card, Flex, Link as TULink } from 'theme-ui';
import { AppRoutePath } from 'Pages/routes';
import { motion, useCycle } from 'framer-motion';
import NavContent from './NavContent';
import Theme from 'Common/themePeazy.json';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { SearchParam } from 'Common/functions/Search';
import { AccountType } from 'Common/functions/AccountType';
import IdentityContext from 'Common/context/IdentityContext';
import { useRouter } from 'next/router';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import HeaderActions from '../SiteHeader/HeaderActions';
import UpgradeAnonymousAccount from 'Common/components/UpgradeAnonymousAccount';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  getAnonymousUserIdToken,
  getLoginAndMergeUrl,
  getLoginOrLoginAndMergeUrl
} from 'Common/functions/UpgradeAnonymousAccount';
import { getAuth } from 'firebase/auth';

export enum NavigationContentType {
  CUSTOMER = 0,
  SERVICE_PROVIDER = 1,
  LOCATION_OWNER = 2,
  ANONYMOUS_CUSTOMER = 3,
}

type NavigationProps = {
  navContentType?: NavigationContentType;
  onClickLogo?: () => void;
  onSelect?: () => void;
  children?: React.ReactNode;
  prefix?: React.ReactNode;
  hideBackButton?: boolean;
  hideSignupButton?: boolean;
  signupButtonLink?: AppRoutePath;
};

const logoRedirectUrl = process.env.NEXT_PUBLIC_LOGO_REDIRECT_URL ||
  AppRoutePath.INDEX;

const Navigation: React.FC<NavigationProps> = ({
  navContentType,
  children,
  onClickLogo,
  onSelect,
  hideSignupButton,
  signupButtonLink,
}) => {
  const [isOpen, cycleIsOpen] = useCycle(false, true);
  const navRef = useRef<HTMLDivElement | null>();
  const router = useRouter();
  const currentIcon = isOpen ? (
    <MenuClose onClick={(): void => cycleIsOpen()} />
  ) : (
    <MenuOpen
      onClick={(): void => cycleIsOpen()}
    />
  );
  const { accountType, ownerUid } = useContext(IdentityContext);
  const isLoggedIn = Boolean(ownerUid);
  const { getIdentityQuery, getUserQuery } = useContext(IdentityContext);
  const [isUpgradeAnonAccountOpen, setIsUpgradeAnonAccountOpen] =
    useState(false);

  const getLoginUrl = useCallback(async () => {
    const anonUser = getAuth().currentUser;
    const anonUserIdToken = anonUser?.isAnonymous ?
      await getAnonymousUserIdToken(anonUser) :
      '';
    const loginUrl =
      getLoginOrLoginAndMergeUrl(anonUserIdToken, router, getLoginAndMergeUrl);

    return loginUrl;
  }, [getAuth().currentUser, router.asPath]);

  const NavigationContent: React.FC = () => {
    const NavItems = React.memo<{
      navContentType: number | undefined;
      isLoggedIn: boolean
    }>(({ navContentType, isLoggedIn }) => {

      return (
        <Flex
          py={[40, 40, 0]}
          sx={{
            flexDirection: ['column', 'column', 'row'],
            alignItems: 'flex-start',
            justifyContent: ['space-between', 'space-between', 'flex-end'],
            whiteSpace: 'nowrap',
          }}
        >
          <Flex
            sx={{
              flexWrap: 'nowrap',
              justifyContent: ['flex-start', 'flex-start', (navContentType === NavigationContentType.ANONYMOUS_CUSTOMER ? 'space-evenly' : 'flex-end')],
              alignItems: ['flex-start', 'flex-start', 'center'],
              gap: '1rem',
              flexDirection: ['column', 'column', 'row'],
              color: ['primary', 'primary', 'secondary'],
              '& > *': {
                flexBasis: ['10%', '10%', 'unset'],
              },
            }}
          >
            {isLoggedIn ? (
              <NavContent
                contentType={navContentType || NavigationContentType.CUSTOMER}
                onSelect={() => {
                  isOpen && cycleIsOpen();
                  onSelect && onSelect();
                }}
                value={router.query[SearchParam.VIEW] as string}
                onClickSignup={() => setIsUpgradeAnonAccountOpen(true)}
                onClickLogin={() => {
                  getLoginUrl().then(url => router.push(url));
                }}
              />
            ) : (
              <HeaderActions
                loggedIn={false}
                hideSignupButton={hideSignupButton}
                hideDashboardButton
                signupButtonLink={signupButtonLink}
              />
            )}
          </Flex>
        </Flex>
      );
    });

    const NavSlideup = isOpen && (
      <Box
        pt={65}
        sx={{
          position: 'fixed',
          width: '100%',
          display: ['unset', 'unset', 'none'],
          top: '0',
          zIndex: 2,
        }}
        ref={navRef}
      >
        <motion.div
          animate={{ y: 0 }}
          transition={{ duration: 0.3, type: 'ease-in' }}
          initial={{ y: 800 }}
          style={{
            position: 'absolute',
            width: '100%'
          }}
        >
          <Card
            sx={{
              height: ['100vh'],
              borderRadius: '0 0  0px 0px',
              boxShadow: 'none',
            }}
          >
            <NavItems
              navContentType={navContentType}
              isLoggedIn={isLoggedIn}
            />
          </Card>
        </motion.div>
      </Box>
    );

    return (
      <>
        <Flex
          sx={{
            zIndex: 9,
            flexDirection: 'column',
            background: Theme.colors.gray99,
            justifyContent: 'flex-start',
            alignItems: 'center',
            position: 'sticky',
            top: 0,
            flexShrink: 0,
            height: ['65px', '65px', '112px'],
            p: ['1rem 1.2rem', '1.5rem 3rem'],
            borderBottom: '1px solid black'
          }}
        >
          <Flex
            sx={{
              height: '100%',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Flex sx={{
              alignItems: 'center',
              columnGap: ['1rem', '1rem', '1.5rem'],
              justifyContent:
                ['flex-start', 'flex-start', 'space-between'],
              width: ['90%', '90%', 'unset'],
            }}>
              <Flex sx={{
                gap: '1.2rem',
                alignItems: 'center'
              }}
              >
                <TULink as="span">
                  <HeaderLogo
                    onClickLogo={() => {
                      isOpen && cycleIsOpen();
                      if (onClickLogo) {
                        onClickLogo();
                      } else {
                        if (!isLoggedIn) {
                          window.location.href = logoRedirectUrl;
                        } else {
                          if (accountType === AccountType.Customer) {
                            router.push(AppRoutePath.BROWSE_SERVICE_TYPES);
                          } else {
                            router.push(AppRoutePath.DASHBOARD);
                          }
                        }
                      }
                    }}
                  />
                </TULink>
              </Flex>
              <Box sx={{ maxWidth: ['60%', '70%'] }}>
                {children}
              </Box>
            </Flex>
            <Flex
              sx={{
                display: ['none', 'none', 'block'],
                minWidth: '500px',
              }}
            >
              <NavItems
                navContentType={navContentType}
                isLoggedIn={isLoggedIn}
              />
            </Flex>
            <Box
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                right: '1rem',
                pt: '6px',
                display: ['unset', 'unset', 'none'],
                color: Theme.colors.darkBlue
              }}
            >
              {isLoggedIn ? (
                currentIcon
              ) : (
                <HeaderActions
                  loggedIn={false}
                  hideSignupButton={hideSignupButton}
                  hideDashboardButton
                  signupButtonLink={signupButtonLink}
                  testIdPrefix="mobile"
                />
              )}
            </Box>
          </Flex>
        </Flex>
        {NavSlideup}
      </>
    );
  };

  useEffect(() => {
    if (!navRef.current) {
      return;
    }

    if (isOpen) {
      disableBodyScroll(navRef.current);
    } else {
      enableBodyScroll(navRef.current);
    }

    return () => clearAllBodyScrollLocks();
  }, [isOpen]);

  return (
    <>
      <NavigationContent />
      <UpgradeAnonymousAccount
        isOpen={isUpgradeAnonAccountOpen}
        title="Signup To Complete Your Booking"
        onClose={() => setIsUpgradeAnonAccountOpen(false)}
        onSigninSuccess={() => {
          setIsUpgradeAnonAccountOpen(false);
          getIdentityQuery.refetch();
          getUserQuery.refetch();
          logEvent(
            getAnalytics(),
            'upgrade_anon_account_navigation',
          );
        }}
      />
    </>
  );
};

export default Navigation;

